<template>
    <div class="app-subscriber-logo">
        <img class="app-subscriber-logo__logo" src="@/assets/svg/tipalti-logo.svg" :alt="tipaltiLogo" />
    </div>
</template>
<script>
import langConsts from '@/lang/en';

export default {
    name: 'AppSubscriberLogo',
    computed: {
        tipaltiLogo() {
            return langConsts.tipaltiLogo;
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-logo {
    border-bottom: 1px solid $mercury;
    display: flex;
    justify-content: center;

    &__logo {
        margin: 1.5rem;
    }
}
</style>
