<template>
    <div class="consent-scopes-description-label">
        {{ text }}
    </div>
</template>
<script>
export default {
    name: 'ConsentScopesDescriptionLabel',
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss">
.consent-scopes-description-label {
    color: $daintree;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;

    margin-top: 0.125rem;
}
</style>
