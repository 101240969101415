<template>
    <div class="consent-scopes-horizontal-divider"></div>
</template>
<script>
export default {
    name: 'ConsentScopesHorizontalDivider',
    props: {
        text: {
            type: String,
            default: ''
        }
    }
};
</script>
<style lang="scss">
.consent-scopes-horizontal-divider {
    margin: 0 0.5rem 0 0.5rem;
    background: $white;
    height: 2px;
}
</style>
