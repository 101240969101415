<template>
    <div class="consent-scopes-list">
        <div v-for="item in scopes" :key="item.displayName" class="consent-scopes-list__item">
            <consent-scopes-list-item :name="item.displayName" :description="item.description" />
            <consent-scopes-horizontal-divider />
        </div>
        <div class="consent-scopes-list__agreement">
            {{ consentAllowAgreementText }}
        </div>
        <app-subscriber-checker :label="consentAllowText" class="consent-scopes-list__checkbox" @input="notifyCaller" />
    </div>
</template>
<script>
import ConsentScopesListItem from './ConsentScopesListItem';
import ConsentScopesHorizontalDivider from './ConsentScopesHorizontalDivider';
import AppSubscriberChecker from '@/components/AppSubscriber/common/AppSubscriberChecker';
import langConsts from '@/lang/en';

const consentToggledEventName = 'consentToggled';
export default {
    name: 'ConsentScopesList',
    components: {
        ConsentScopesListItem,
        ConsentScopesHorizontalDivider,
        AppSubscriberChecker
    },
    props: {
        scopes: {
            type: Array,
            required: true
        }
    },
    computed: {
        consentAllowText() {
            return langConsts.consentAllow;
        },
        consentAllowAgreementText() {
            return langConsts.consentAllowAgreementText;
        }
    },
    methods: {
        notifyCaller(isChecked) {
            this.$emit(consentToggledEventName, isChecked);
        }
    }
};
</script>
<style lang="scss" scoped>
.consent-scopes-list {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    max-height: 14.75rem;

    margin-top: 0.5rem;
    background-color: $wild-sand;

    &__checkbox {
        margin: 0 0 1.05rem 0.5rem;
    }
    &__agreement {
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 0.75rem;
        margin: 1.05rem 0.875rem 0.5rem 0.5rem;
    }
}
</style>
