<template>
    <div class="consent-scopes-list-item">
        <div class="consent-scopes-list-item__name">{{ name }}</div>
        <div class="consent-scopes-list-item__description">{{ description }}</div>
    </div>
</template>
<script>
export default {
    name: 'ConsentScopesListItem',
    props: {
        name: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss">
.consent-scopes-list-item {
    color: $outer-space;

    padding: 0.5rem;
    text-align: left;

    &__name {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 130%;
    }

    &__description {
        font-weight: 400;
        font-size: 0.625rem;
        line-height: 0.75rem;
    }
}
</style>
