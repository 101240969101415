<template>
    <div class="app-subscriber-header">
        <app-subscriber-logo />
        <app-subscriber-title :title="title" />
    </div>
</template>
<script>
import AppSubscriberLogo from './AppSubscriberLogo';
import AppSubscriberTitle from './AppSubscriberTitle';

export default {
    name: 'AppSubscriberHeader',
    components: {
        AppSubscriberLogo,
        AppSubscriberTitle
    },
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>
