<template>
    <div class="app-subscriber-label">
        {{ text }}
    </div>
</template>
<script>
export default {
    name: 'AppSubscriberLabel',
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-label {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $daintree;
    margin-bottom: 0.125rem;
}
</style>
