<template>
    <div class="app-subscriber-checker" @click.prevent="handleInput">
        <span
            class="app-subscriber-checker__checkbox"
            :class="{
                'app-subscriber-checker__checkbox--checked': isChecked,
                'app-subscriber-checker__checkbox--disabled': disabled
            }"
        >
            <font-awesome-icon v-show="isChecked" class="fa-check fa-inverse app-subscriber-checker__checkbox-icon" :icon="['far', 'check']" />
            <input type="checkbox" :disabled="disabled" :name="name" :value="isChecked" class="app-subscriber-checker__input" />
        </span>
        <span v-if="!isLabelEmpty" class="app-subscriber-checker__label">{{ label }}</span>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'AppSubscriberChecker',
    props: {
        name: {
            type: String,
            default: () => {
                return uuidv4();
            }
        },
        value: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isChecked: false
        };
    },
    computed: {
        isLabelEmpty() {
            return isEmpty(this.label);
        }
    },
    watch: {
        value(val) {
            this.isChecked = val;
        }
    },
    mounted() {
        this.isChecked = this.value;
    },
    methods: {
        handleInput() {
            if (this.disabled) {
                return;
            }
            this.isChecked = !this.isChecked;
            this.$emit('input', this.isChecked);
        }
    }
};
</script>

<style lang="scss">
.app-subscriber-checker {
    $base: &;

    * {
        box-sizing: border-box !important;
    }

    &:hover {
        #{$base}__checkbox {
            border-color: $spindle;
            &--checked {
                background-color: $spindle;
            }
        }
    }

    cursor: pointer;
    display: flex;
    height: 0.75rem;
    margin-right: 0.438rem;
    width: max-content;
    &__checkbox {
        align-content: center;
        border: 1px solid $cornflower-blue;
        border-radius: 0.1rem;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 0.75rem;

        &--checked {
            background-color: $cornflower-blue;
            border: 1px solid $cornflower-blue;
            color: $white;
            &.app-subscriber-checker__checkbox--disabled {
                background-color: $silver;
            }
        }
        &--disabled {
            background-color: $white;
            border: 1px solid $silver;
            cursor: not-allowed;
            &:hover {
                border-color: $silver;
                cursor: not-allowed;
            }
        }
    }
    &__input {
        display: none;
    }
    &__checkbox-icon {
        display: block;
        font-size: 0.6rem;
    }
    &__label {
        align-items: center;
        display: flex;
        margin-left: 0.375rem;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.94rem;
        color: $daintree;
    }
}
</style>
