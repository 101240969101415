<template>
    <div class="consent-scopes-actions">
        <tip-button-border
            id="confirmButton"
            :type="confirmButtonType"
            :disabled="disabled"
            :loading="submitting"
            class="consent-scopes-actions__submit"
            @click="notifyConsentSubmit"
        >
            {{ buttonTextConfirm }}
            <template v-slot:loading>
                <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
            </template>
        </tip-button-border>
        <tip-button-border
            id="cancelButton"
            :type="cancelButtonType"
            :disabled="cancelDisabled"
            :loading="cancelling"
            class="consent-scopes-actions__cancel"
            @click="notifyConsentCancel"
        >
            {{ buttonTextCancel }}
            <template v-slot:loading>
                <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
            </template>
        </tip-button-border>
    </div>
</template>
<script>
import langConsts from '@/lang/en';

export default {
    name: 'ConsentScopesActions',
    props: {
        disabled: {
            type: Boolean,
            required: true
        },
        submitting: {
            type: Boolean,
            required: true
        },
        cancelling: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        buttonTextConfirm() {
            return langConsts.buttonTextConfirm;
        },
        buttonTextCancel() {
            return langConsts.buttonTextCancel;
        },
        cancelButtonType() {
            return 'accent';
        },
        confirmButtonType() {
            return this.submitting ? 'secondary' : 'primary';
        },
        cancelDisabled() {
            return this.cancelling || this.submitting;
        },
        consentSubmitEventName() {
            return 'consent-submit';
        },
        consentCancelEventName() {
            return 'consent-cancel';
        }
    },
    methods: {
        notifyConsentSubmit() {
            this.$emit(this.consentSubmitEventName);
        },
        notifyConsentCancel() {
            this.$emit(this.consentCancelEventName);
        }
    }
};
</script>
<style lang="scss">
.consent-scopes-actions {
    display: flex;
    flex-direction: row-reverse;
    width: 10.25rem;

    &__submit {
        margin-left: 0.5rem;
    }
}
</style>
