<template>
    <div class="consent-scopes-context-description">
        <div class="consent-scopes-context-description__payer">
            <app-subscriber-label :text="payerLabel" />
            <consent-scopes-description-label :text="payerName" />
        </div>
        <div class="consent-scopes-context-description__permissions">
            <app-subscriber-label :text="permissionsLabel" />
            <consent-scopes-description-label :text="permissionsLabelText" />
        </div>
    </div>
</template>
<script>
import langConsts from '@/lang/en';
import AppSubscriberLabel from '@/components/AppSubscriber/common/AppSubscriberLabel';
import ConsentScopesDescriptionLabel from './ConsentScopesDescriptionLabel';

export default {
    name: 'ConsentScopesContextDescription',
    components: {
        AppSubscriberLabel,
        ConsentScopesDescriptionLabel
    },
    props: {
        payerName: {
            type: String,
            required: true
        },
        applicationName: {
            type: String,
            required: true
        }
    },
    computed: {
        payerLabel() {
            return langConsts.labelPayerConsent;
        },
        permissionsLabel() {
            return langConsts.labelPermissionsConsent;
        },
        permissionsLabelText() {
            return `${this.applicationName} ${langConsts.labelPermissionsConsentText}`;
        }
    }
};
</script>
<style lang="scss">
.consent-scopes-context-description {
    &__payer {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    &__persmissions {
        display: flex;
        flex-direction: column;
    }
}
</style>
