<template>
    <div class="consent-scopes">
        <div>
            <app-subscriber-header :title="consentTitle" />
            <div v-if="shouldDisplayConsent">
                <consent-scopes-context-description
                    class="consent-scopes__context-description"
                    :payer-name="payerName"
                    :application-name="applicationName"
                />
                <div class="consent-scopes__contents">
                    <consent-scopes-list :scopes="clientScopes" @consentToggled="updateConsentChecked" />
                </div>
                <div class="consent-scopes__actions">
                    <consent-scopes-actions
                        :disabled="actionsDisabled"
                        :submitting="isSubmitting"
                        :cancelling="isCancelling"
                        @consent-submit="grantConsentSubmit"
                        @consent-cancel="clickOnCancelConsent"
                    />
                </div>
            </div>
            <div v-else class="consent-scopes__loading">
                <tip-spinner />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import AppSubscriberHeader from '@/components/AppSubscriber/AppSubscriberHeader';
import ConsentScopesContextDescription from './ConsentScopesContextDescription';
import ConsentScopesList from './ConsentScopesList';
import ConsentScopesActions from './ConsentScopesActions';

export default {
    name: 'ConsentScopes',
    components: {
        AppSubscriberHeader,
        ConsentScopesContextDescription,
        ConsentScopesList,
        ConsentScopesActions
    },
    data() {
        return {
            consentedScopes: [],
            isLoadingLocal: false,
            isSubmitting: false,
            isCancelling: false,
            isConsentChecked: false
        };
    },
    computed: {
        ...mapState('consent', ['oidcReturnUrl', 'clientScopes', 'payerName', 'applicationName', 'showErrorConsent']),

        consentTitle() {
            return !this.shouldDisplayConsent ? '' : langConsts.titleConsentScopes;
        },
        loginFailedUrl() {
            return `${loginConsts.urls.baseLoginAppUrl}${loginConsts.urls.urlPathFailedLoginAttempt}`;
        },
        actionsDisabled() {
            return !this.isConsentChecked || this.isSubmitting || this.isCancelling;
        },
        shouldDisplayConsent() {
            return !this.isLoadingLocal && this.clientScopes && this.clientScopes.length > 0;
        }
    },
    async created() {
        this.isLoadingLocal = true;
        await this.getScopesByLoginState();
        this.isLoadingLocal = false;
        this.consentedScopes = this.clientScopes?.map((x) => x.name);
    },
    methods: {
        ...mapActions('consent', ['getScopesByLoginState', 'grantConsent']),

        async grantConsentSubmit() {
            this.isSubmitting = true;
            const redirecUrl = await this.grantConsent({
                oidcReturnUrl: this.oidcReturnUrl,
                consentedScopes: this.consentedScopes
            });
            if (!this.showErrorConsent) {
                window.location.replace(redirecUrl);
            } else {
                this.isSubmitting = false;
            }
        },
        clickOnCancelConsent() {
            this.isCancelling = true;
            window.location.replace(this.loginFailedUrl);
        },
        updateConsentChecked(isChecked) {
            this.isConsentChecked = isChecked;
        }
    }
};
</script>

<style lang="scss">
.consent-scopes {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__contents {
        margin: 0 3rem 0 3rem;

        /* width */
        ::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: $gallery;
            border-radius: 3.75rem;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgba($daintree, 0.5);
            border-radius: 3.75rem;
        }
    }
    &__context-description {
        margin: 0 3rem 0 3rem;
    }
    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 11.875rem;
    }
    &__actions {
        display: flex;
        flex-direction: row-reverse;

        margin: 3.5rem 1.5rem 1.5rem 0;
    }
    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
}
</style>
